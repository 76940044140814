import React from 'react'
import "./Footer.scss";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
const Footer = () => {
    return (
        <>
            <footer>
                <div className='container' id='footer'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <h3>company</h3>
                            <ul>
                                <li>about us</li>
                                <li>events</li>
                                <li>contact us</li>
                            </ul>
                        </div>
                        <div className='col-md-6 brand'>
                            <a class="brand-logo" href="/"><h1><span>rent</span>vesting.</h1></a>
                            <p>Lorem ipsum dolor sit amet. consectetur adipiscing elit,consectetur adipiscing elit,</p>
                            <div className='social-links'>
                                <a href='' className='first-child'><FaFacebookF /></a>
                                <a href=''><FaInstagram /></a>
                                <a href=''><FaXTwitter /></a>
                                <a href=''><FaLinkedinIn /></a>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <h3>get in touch</h3>
                            <ul>
                            <span className='display-flex'> <a href='tel:(206) 342-8631'><span><BiSolidPhoneCall /></span><li>(206) 342-8631</li></a></span>
                                <span className='display-flex'><a href='mailto:Renvesting@gmail.com' target='_blank'><span><MdEmail /></span> <li>Renvesting@gmail.com</li></a></span>
                                <span className='display-flex'> <a href='#' target='_blank'><span><FaMapMarkerAlt /></span> <li>Suite 196 9648 Rice View, Shanahanhaven</li></a></span>
                            </ul>
                        </div>
                    </div>
                    <div className='row textcenter'>
                        <p>Terms And Conditions |
                        Privacy Policy |</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
