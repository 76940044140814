import React, { useEffect } from 'react';
import "./Home.scss";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { FaLinkedinIn } from "react-icons/fa6";
import { HiOutlineChartPie } from "react-icons/hi2";
import { FaLaptopCode } from "react-icons/fa6";
import { TbZoomMoney } from "react-icons/tb";
import { VscGraph } from "react-icons/vsc";
import Banner from "../../Assests/Img/banner.webp";
import barries1 from "../../Assests/Img/barriers1.webp";
import barries2 from "../../Assests/Img/barriers2.webp";
import kunal from "../../Assests/Img/kunal.webp";
import { FaMapMarkerAlt } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import investImg from "../../Assests/Img/investImg.webp";
import event1 from "../../Assests/Img/event1.webp";
import event2 from "../../Assests/Img/event2.webp";
import event3 from "../../Assests/Img/event3.webp";
import Nipun from "../../Assests/Img/Nipun.webp";
import Gagan from "../../Assests/Img/gagan.webp";

const Home = () => {
    const options = {
        items: 2,
        loop: true,
        margin: 10,
        nav: true,
        rewind: true,
        // autoplay: true,
        // navText: [
        //     `<span class="custom-prev"><img img src="${leftArrow}" alt="Prev"></span>`,
        //     `<span class="custom-next"><img img src="${rightArrow}" alt="Prev"></span>`
        // ],
    };
    return (
        <>
            <div className='home' id='home'>
                {/* BANNER START */}
                <div className='container' id='banner'>
                    <div className='row'>
                        <div className='col-lg-5 left-side'>
                            <h3 className='short-head'>WHAT IS RENTINVESTING?</h3>
                            <h1 className='main-head'>We are The<br></br> People of Action</h1>
                            <p className='text-desc'>Rentvesting is a dynamic strategy that combines the flexibility of renting with the investment potential of property ownership. Our platform is designed to help you navigate this approach with confidence and ease. We offer comprehensive tools and resources that enable you to make informed decisions, from choosing the right investment properties to optimizing your rental experience.
                                we believe in taking proactive steps to secure your financial future while enjoying the flexibility of renting.</p>
                            <a href='#'><button className='common-btn'>LET'S CONTECT TODAY</button></a>
                        </div>
                        <div className='col-lg-7 right-side'>
                            <img src={Banner} alt='banner' title='banner'></img>
                        </div>
                    </div>
                </div>
                {/* BANNER END */}
                {/* ----------------- */}
                {/* PORTFOLIOS START */}
                <div className='portfolios bg-img' id='portfolios'>
                    <h1 className='main-head'>Powering next-generation Portfolios</h1>
                    <h2>3,160,597</h2>
                    <p>People Are Using Fundrise To Start Investing Smarter</p>
                    <p className='Figures'>Figures as of: 02/08/2024</p>
                </div>
                {/* PORTFOLIOS END */}
                {/* ----------------- */}
                {/* BARRIERS START */}
                <div className='barriers' id='barriers'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img src={barries1} alt='barries1' title='barries1' className='barriers1'></img>
                            </div>
                            <div className='col-lg-3'>
                                <img src={barries2} alt='barries2' title='barries2'></img>
                            </div>
                            <div className='col-lg-5 right-side'>
                                <h1 className='main-head'>Tearing Down Barriers To The Best Investments</h1>
                                <p className='text-desc'>we are committed to tearing down the barriers that often make investing seem daunting or out of reach. We understand that the investment landscape can be complex & intimidating. with traditional approaches sometimes creating obstacles that prevent individuals from accessing the opportunities they deserve. That's why we focus on simplifying the investment process and making it more transparent. Our innovative solutions are designed to demystify the world of investing, offering clear, actionable insights that empower you to make informed decisions.
                                    We provide expert guidance tailored to your unique financial goals, ensuring that you have the knowledge and resources needed to navigate the market with confidence. Our mission is to eliminate the guesswork and streamline your path to success, whether you're just starting out or looking to diversify your portfolio.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BARRIERS END */}
                {/* --------------------- */}
                {/* INVEST START */}
                <div className='invest' id='invest'>
                    <div className='container'>
                        <div className='row'>
                            <h1 className='main-head'>Invest Without Compromise</h1>
                            <p className='text-desc'>Our $7+ billion investment portfolio aims to harness the most powerful long-term drivers of the U.S. economy. In today's macroeconomic environment, we
                                believe the Fundrise portfolio is as well-positioned as any in the world to deliver stable, market-beating returns.</p>
                            <img src={investImg} alt='investImg' title='investImg' className='barriers1'></img>
                        </div>
                    </div>
                </div>
                {/* INVEST END */}
                {/* ---------------------- */}
                {/* COUNT NUMBER START */}
                <div className='alone' id='alone'>
                    <div className='container'>
                        <div className='row'>
                            <h1 className='main-head'>We Couldn't Do This Alone</h1>
                            <p className='text-desc'>We were founded in Silicon Valley, but our chapters have reached over 5 million entrepreneurs across 125 countries (and counting!)</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className='circle'>
                                    <h3>600+ </h3>
                                    <p>Chapters</p>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='circle'>
                                    <h3>125+</h3>
                                    <p>Countries</p>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='circle'>
                                    <h3>1,700+ </h3>
                                    <p>Chapter Team Members</p>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='circle'>
                                    <h3>5M </h3>
                                    <p>Community Members Worldwide</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ALONE END */}
                {/* --------------------- */}
                <div className='events' id='events'>
                    <div className='container'>
                        <h1 className='main-head'>upcoming events</h1>
                        <div className='row events-spacing'>
                            <div className='col-lg-4 events-box events-box1'>
                                <div className='event-text'>
                                    <img src={event1} alt='event1' title='event1'></img>
                                    <h2>Be There, Be Inspired</h2>
                                    <p className='text-desc'>Join us for an in-depth masterclass where our expert, Kunal, will share actionable strategies and insights on how</p>
                                </div>
                                <div className='event-btn'>
                                    <button className='btn btn1'><FaMapMarkerAlt /></button>
                                    <button className='btn btn2'><SlCalender /><p>03 August 2024</p></button>
                                </div>
                            </div>
                            <div className='col-lg-4 events-box events-box2'>
                                <div className='event-text'>
                                    <img src={event2} alt='event2' title='event2'></img>
                                    <h2>Next Rentvesting</h2>
                                    <p className='text-desc'>Tune in to our live webinar with Kunal as he explores the latest trends in the rentvesting market.</p>
                                </div>
                                <div className='event-btn'>
                                    <button className='btn btn1'><FaMapMarkerAlt /></button>
                                    <button className='btn btn2'><SlCalender /><p>10 August 2024</p></button>
                                </div>
                            </div>
                            <div className='col-lg-4 events-box events-box3'>
                                <div className='event-text'>
                                    <img src={event3} alt='event3' title='event3'></img>
                                    <h2>Meet the Minds</h2>
                                    <p className='text-desc'>Expand your network and connect with other rentvestors at our exclusive networking event.</p>
                                </div>
                                <div className='event-btn'>
                                    <button className='btn btn1'><FaMapMarkerAlt /></button>
                                    <button className='btn btn2'><SlCalender /><p>17 August 2024</p></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------- */}
                {/* MEET THE HOST START */}
                <div className='meet-the-host container' id='meet-the-host'>
                    <div className='row'>
                        <h1 className='main-head'>Meet the host</h1>
                        <div className='col-md-4 left-side-main'>
                            <div className='left-side'>
                                <img src={kunal} alt='kunal' title='kunal'></img>
                                <h2>HELLO, I'M<br></br> KUNAL DEV KUMAR</h2>
                                <p>Performance, insights,<br></br> Analytics and Transfromation</p>
                                <a href=''><FaLinkedinIn /></a>
                            </div>
                        </div>
                        <div className='col-md-8 right-side'>
                            <h4>About the host</h4>
                            <p className='text-desc'>I am an experienced Insights and Analytics Professional with strong Commercial acumen and demonstrated history of assisting Executives design & implement Business Strategies by delivering evidence based recommendations. Skilled in Business Insights, Commercial Analysis, Business Partnering. Stakeholder engagement, building Dashboards, Performance Reporting (and Automation), Business and Marketing Analytics, Marketing Campaigns Analysis and Tracking. Pricing, Budgeting. Forecasting, New Product Development, Business Cases, Business Transformation etc with a Master's Degree focused in Management Information Systems.</p>
                            <h4 className='main-head location'>Location</h4>
                            <p className='text-desc'>Australia</p>
                            <a href='#'><button className='common-btn'>CONTACT US</button></a>
                        </div>
                    </div>
                </div>
                {/* MEET THE HOST END */}
                {/* --------------------- */}
                {/* JOIN US START */}
                <div className='join' id='join'>
                    <div className='container'>
                        <h1 className='main-head'>Join Us</h1>
                        <p className='text-desc'>For Monthly Real Estate Investor Networking Events North West. South And Inside Of Atlanta</p>
                        <div className='row for-top-spacing'>

                            <div className='col-md-3'>
                                <div className='icon-box icon-box1'>
                                    <HiOutlineChartPie />
                                    <h3>Easy Diversification</h3>
                                    <p>Easy diversification in rentvesting involves spreading your investments across various property markets and types to minimize risk and enhance</p>
                                    <hr></hr>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='icon-box icon-box2'>
                                    <FaLaptopCode />
                                    <h3>Digital Analytics</h3>
                                    <p>Digital analytics is a powerful tool for optimizing your rentvesting strategy by providing valuable insights into market trends, property</p>
                                    <hr></hr>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='icon-box icon-box3'>
                                    <TbZoomMoney />
                                    <h3>Monthly Cashflow</h3>
                                    <p>In rentvesting, managing monthly cashflow is crucial for sustaining a profitable investment strategy. Monthly cashflow refers to the net</p>
                                    <hr></hr>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='icon-box icon-box4'>
                                    <VscGraph />
                                    <h3>
                                        Capital Appreciation</h3>
                                    <p>Capital appreciation refers to the increase in the value of your property investment over time. In the context of rentvesting.</p>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* JOIN US END */}
                {/* --------------------- */}
                {/* TESTIMONIAL START */}
                <div className='container testimonial' id='testimonial'>
                    <h3 className='short-head'>Success Through Smart Rentvesting</h3>
                    <h1 className='main-head'>Inspirational Success Stories</h1>
                    <div className='row'>
                        <div className='carousel'>
                            <OwlCarousel className='owl-theme' {...options}>
                                <div className='item'>
                                    <div className='item-inner'>
                                        <div className='img-side'>
                                            <img src={Nipun} alt='Nipun' title='Nipun' />
                                            <h3>Next Rentvesting</h3>
                                            <p>follow on <a href='#'><FaLinkedinIn /></a></p>
                                        </div>
                                        <div className='text-side'>
                                            <p>Hi, I'm Nipun, and I'm excited to share my journey of turning a dream into reality through rentvesting. When I first started my career, I was faced with the daunting challenge of high property prices in the city where I wanted to live Homeownership seemed like an impossible goal, but I was determined not to let this stand in my way.<br></br>
                                                Instead of giving up, I chose to embrace rentvesting. I rented an apartment in the city I loved, enjoying the vibrant lifestyle and convenience of being close to work.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='item-inner'>
                                        <div className='img-side'>
                                            <img src={Gagan} alt='Gagan' title='Gagan' />
                                            <h3>gagan takker</h3>
                                            <p>follow on <a href='#'><FaLinkedinIn /></a></p>
                                        </div>
                                        <div className='text-side'>
                                            <p>Hi, I'm Gagan, and I'm excited to share my journey of turning a dream into reality through rentvesting. When I first started my career, I was faced with the daunting challenge of high property prices in the city where I wanted to live Homeownership seemed like an impossible goal, but I was determined not to let this stand in my way.<br></br>
                                                Instead of giving up, I chose to embrace rentvesting. I rented an apartment in the city I loved, enjoying the vibrant lifestyle and convenience of being close to work.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='item-inner'>
                                        <div className='img-side'>
                                            <img src={barries1} alt='barries1' title='barries1' />
                                            <h3>Next Rentvesting</h3>
                                            <p>follow on <a href='#'><FaLinkedinIn /></a></p>
                                        </div>
                                        <div className='text-side'>
                                            <p>Hi, I'm Nipun, and I'm excited to share my journey of turning a dream into reality through rentvesting. When I first started my career, I was faced with the daunting challenge of high property prices in the city where I wanted to live Homeownership seemed like an impossible goal, but I was determined not to let this stand in my way.<br></br>
                                                Instead of giving up, I chose to embrace rentvesting. I rented an apartment in the city I loved, enjoying the vibrant lifestyle and convenience of being close to work.</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>

                    </div>
                </div>
                {/* TESTIMONIAL END */}
                {/* ---------------------- */}
                <div className='mission' id='mission'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-6 left-side'>
                                <img src={barries1} alt='barries1' title='barries1'></img>
                                <h3 className='main-head'>vision</h3>
                                <p className='text-desc'>Our vision is to redefine property investment through the lens of rentvesting, creating a future where everyone can enjoy their ideal living environment while building substantial wealth through strategic real estate investments. We aspire to lead the industry in making rentvesting accessible and advantageous, fostering a new standard of financial freedom and lifestyle balance. By continuously innovating and staying ahead of market trends, we aim to empower a diverse range of investors to thrive, transforming their real estate ambitions into tangible, long-term success.</p>
                            </div>
                            <div className='col-md-6 right-side'>
                                <img src={investImg} alt='barries' title='barries2'></img>
                                <h3 className='main-head'>Mission</h3>
                                <p className='text-desc'>our mission is to make rentvesting a practical and effective strategy for individuals seeking to balance lifestyle preferences with property investment goals. We are dedicated to simplifying the rentvesting process by providing expert guidance, in-depth market analysis, and personalized solutions. Our goal is to help you live where you want while investing in property where you can achieve strong financial returns. By removing the complexities and barriers associated with rentvesting, we ensure you can confidently navigate both the rental and investment markets, maximizing your opportunities for success.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------------- */}
                <div className='container faq' id='faq'>
                    <h1 className='main-head'>Explore Our FAQ</h1>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    1. What is Rentvesting?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Rentvesting is a property investment strategy where you rent a home in a location that suits your lifestyle while purchasing an investment property in a different area. This allows you to enjoy your pre- ferred living environment and simultaneously build wealth through property investments.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    2. Why should I consider rentvesting?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Rentvesting can be an effective way to balance your lifestyle preferences with your financial goals. It enables you to live in a desirable area without the financial burden of buying property there, and invest in more affordable markets that offer strong rental yields and growth potential.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    3. How do I determine the best locations for rentvesting?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Choosing the right locations involves researching both the rental market where you want to live and the investment market where you plan to buy. Factors to consider include property prices, rental yields, market growth, and your personal lifestyle needs. Our team can help you analyze these aspects to find the best opportunities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    4. What are the financial benefits of rentvesting ?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Rentvesting offers several financial benefits, including potential tax advantages from rental income, diversification of your investment portfolio, and the opportunity for capital growth in the investment property. It can also provide better cash flow management by allowing you to invest in more affordable markets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home

